<template>
  <div>
    <Breadcrumbs :items="crumbs" />
    <div class="dashboard__main">
      <div class="dashboard__main--header">
        <h1 class="ml-3 mt-3">Edit Product</h1>
        <router-link :to="{ name: 'dashProducts' }">
          <button class="btn"><i class="fad fa-arrow-left fa-2x"></i></button>
        </router-link>
      </div>
      <hr>
    
      <div class="form">
        <form @submit.prevent="editProduct">

          <div class="flex flex-wrap">
            <div class="flex__col1">

              <transition name="fadeStop">
                <div class="dashboard__grid mb-3">
                  <div v-for="item in product.pics" class="dashboard__grid--item">
                    <img :src="item" alt="">
                    <button @click.prevent="deleteSlide(item)" class="dashboard__grid--item--delete">DELETE</button>
                  </div>
                </div>
              </transition>

              <div class="mb-5">
                <croppa 
                  v-model="image"
                  :prevent-white-space="false"
                  :width="240"
                  :height="240"
                  :quality="8"
                  canvas-color="#fff"
                  :file-size-limit="2048000"
                  accept=".jpeg,.png,.jpg"
                  initial-size="contain"
                  @new-image="showSave()"
                  @file-size-exceed="onFileSizeExceed"
                  @file-type-mismatch="onFileTypeMismatch"
                ></croppa>
                <transition name="fade">
                  <button v-if="showSaveButton" class="btn btn__primary mt-3" @click.prevent="addImage">
                    Add Image
                    <transition name="fade">
                      <span class="ml-2" v-if="performingRequest">
                      <i class="fa fa-spinner fa-spin"></i>
                      </span>
                    </transition>
                  </button>
                </transition>
              </div>

              <div class="card">
                <form enctype="multipart/form-data">
                  <h3>Upload Spec Sheet (PDF)</h3>
                  <label for="po">Select File:</label>
                  <input type="file" id="po" name="po" accept=".pdf,.doc" class="no-border" @change="uploadFile">
                  <transition name="fade">
                    <Loader v-if="performingRequest" />
                  </transition>
                </form>
              </div>  
            </div>
            <div class="flex__col2">
              <div>
                <label for="category">Category:</label>
                <select name="category" id="category" v-model="product.category" required @change="disabledCheck()">
                  <option v-for="option in categories" v-bind:value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div class="mb-2 mt-2">
                <label for="core" class="mr-3">Core Item?</label>
                <input type="checkbox" id="core" v-model="product.core">
              </div>
              <div class="mb-2 mt-2">
                <label for="visible" class="mr-3">Is Visible?</label>
                <input type="checkbox" id="visible" v-model="product.visible">
              </div>
              <!-- <div class="mb-2 mt-2">
                <label for="stock" class="mr-3">In Stock?</label>
                <input type="checkbox" id="stock" v-model="product.stock">
              </div> -->
              <div>
                <label for="displayOrder">Display Order</label>
                <input type="number" placeholder="20" v-model.trim="product.displayOrder" id="displayOrder" required @keyup="disabledCheck()" />
              </div>
              <!-- <div>
                <label for="line">Contract Line Item #</label>
                <input type="text" placeholder="2000" v-model.trim="product.lineItem" id="line" required @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="approval">NIOSH Approval Number</label>
                <input type="text" placeholder="1235456" v-model.trim="product.approvalNumber" id="approval" @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="establishment">FDA Establishment Number</label>
                <input type="text" placeholder="1235456" v-model.trim="product.establishmentNumber" id="establishment" @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="description">Commodity Description</label>
                <textarea placeholder="N95, Non-Vented..." id="description" cols="30" rows="4" v-model.trim="product.commodityDescription" @keyup="disabledCheck()"></textarea>
              </div>
              <div>
                <label for="unspsc">UNSPSC Code</label>
                <input type="text" placeholder="46182005" v-model.trim="product.unspsc" id="unspsc" required @keyup="disabledCheck()" />
              </div> -->
              <div>
                <label for="part">Manufacturer Part Number</label>
                <input type="text" placeholder="L-188" v-model.trim="product.part" id="part" required @keyup="disabledCheck()" />
              </div>
              <!-- <div>
                <label for="manufacturer">Manufacturer</label>
                <input type="text" placeholder="Harley" v-model.trim="product.manufacturer" id="manufacturer" required @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="color">Color:</label>
                <select name="color" id="color" v-model="product.color" required @change="disabledCheck()">
                  <option v-for="option in colors" v-bind:value="option">
                    {{ option }}
                  </option>
                </select>
              </div> -->
              <!-- <div>
                <label for="size">Size:</label>
                <select name="size" id="size" v-model="product.size" required @change="disabledCheck()">
                  <option v-for="option in sizes" v-bind:value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div>
                <label for="style">Style:</label>
                <select name="style" id="style" v-model="product.style" required @change="disabledCheck()">
                  <option v-for="option in styles" v-bind:value="option">
                    {{ option }}
                  </option>
                </select>
              </div>
              <div>
                <label for="strap">Strap:</label>
                <select name="strap" id="strap" v-model="product.strap" required @change="disabledCheck()">
                  <option v-for="option in straps" v-bind:value="option">
                    {{ option }}
                  </option>
                </select>
              </div> -->
              <!-- <div>
                <label for="itemdescription">Item Description</label>
                <textarea placeholder="N95 (foldable), Standard Size, White Color..." id="itemdescription" cols="30" rows="4" v-model.trim="product.itemDescription" required @keyup="disabledCheck()"></textarea>
              </div> -->
              <!-- <div>
                <label for="msrp">MSRP</label>
                <input type="text" placeholder="1.86" v-model.trim="product.msrp" id="msrp" required @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="contractPrice">Contract Price</label>
                <input type="text" placeholder="1.86" v-model.trim="product.contractPrice" id="contractPrice" required @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="contractPrice5">Contract Price 5M+</label>
                <input type="text" placeholder="1.86" v-model.trim="product.contractPrice5M" id="contractPrice5" required @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="contractPrice10">Contract Price 10M+</label>
                <input type="text" placeholder="1.86" v-model.trim="product.contractPrice10M" id="contractPrice10" required @keyup="disabledCheck()" />
              </div>
              <div>
                <label for="boxUnits">Units Per Box</label>
                <input type="text" placeholder="20" v-model.trim="product.boxUnits" id="boxUnits" required @keyup="disabledCheck()" />
              </div> -->
              <div class="flex justify-space-between">
                <router-link :to="{ name: 'dashProducts' }">
                  <button class="btn btn__outlined mt-4 mb-5">Cancel</button>
                </router-link>
                <button :disabled="isDisabled" class="btn btn__primary mt-4 mb-5 mr-4" type="submit">
                  Save Changes
                  <transition name="fade">
                    <span class="ml-2" v-if="performingRequest2">
                    <i class="fa fa-spinner fa-spin"></i>
                    </span>
                  </transition>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { VueEditor } from "vue2-editor"
import Loader from '@/components/Loader.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
const fb = require('../../../firebaseConfig.js')

export default {
  name: 'dashEditProduct',
  data() {
    return {
      performingRequest: false,
      performingRequest2: false,
      image: null,
      isDisabled: false,
      showSaveButton: false,
    }
  },
  created () {
    this.$store.dispatch("getProductFromId", this.$route.params.id);
    this.$store.dispatch("getCategories")
  },
  computed: {
    ...mapState(['currentUser', 'userProfile', 'product', 'categories']),
    crumbs () {
      let crumbsArray = []
      let step1 = { title: "Dashboard", to: { name: "dashHome"}}
      let step2 = { title: "Products", to: { name: "dashProducts"}}
      let step3 = { title: 'Edit Product', to: false}
      crumbsArray.push(step1)
      crumbsArray.push(step2)
      crumbsArray.push(step3)
      return crumbsArray
    },
  },
  methods: {
    onFileTypeMismatch(file) {
      alert('Invalid file type. Please choose a jpeg or png file.')
    },
    onFileSizeExceed(file) {
      alert('Please choose a file smaller than 2MB')
    },
    showSave() {
      this.showSaveButton = true
      this.disabledCheck()
    },
    deleteSlide(item) {
      this.product.pics.splice(this.product.pics.indexOf(item), 1);
      console.log("deleted")
      this.disabledCheck()
    },
    disabledCheck() {
      if (this.product.displayOrder && this.product.category && this.product.description && this.product.part) {
        return this.isDisabled = false
      } else {
        return this.isDisabled = true
      }
    },
    addImage() {
      this.performingRequest = true
      this.image.generateBlob(
        blob => {
          let downloadURL
          let pics = this.product.pics
          let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
          let picRef = fb.storageRef.child('productPics/' + rand)
          picRef.put(blob).then((snap) => {
             picRef.getDownloadURL().then(function(downloadURL) {
              console.log('File available at', downloadURL)
              pics.push(downloadURL)
            })
          })
          setTimeout(() => {
            this.performingRequest = false
            this.showSaveButton = false
            this.image.remove()
          }, 3000)
        }
      )
    },
    uploadFile(event) {
      console.log(event.target.files)
      let file = event.target.files[0];
      let product = this.product
      this.performingRequest = true
      let rand = (Math.random().toString(36).substring(2, 16) + Math.random().toString(36).substring(2, 16)).toUpperCase()
      let fileRef = fb.storageRef.child('specs/' + rand)
      fileRef.put(file).then((snap) => {
         fileRef.getDownloadURL().then(function(downloadURL) {
          console.log('File available at', downloadURL)
          product.specs = downloadURL
        })
      })
      setTimeout(() => {
        this.performingRequest = false
      }, 3000)
    },
    editProduct() {
      this.performingRequest2 = true
      let product = this.product;
      setTimeout(() => {
        this.$store.dispatch('updateProduct', product)
        this.performingRequest2 = false
        this.product = {}
        this.isDisabled = true
        this.$router.push('/dashboard/products')
      }, 3000)
    },
  },
  components: {
    Loader,
    VueEditor,
    Breadcrumbs
  },
  beforeDestroy(){
    this.performingRequest = false
    this.performingRequest2 = false
    this.image = null
    this.isDisabled = true
    this.showSaveButton = false
    delete this.performingRequest
    delete this.performingRequest2
    delete this.image
    delete this.isDisabled
    delete this.showSaveButton
    this.$store.dispatch('clearProduct')
  }
}
</script>